import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Form, message, Tabs, TabsProps } from "antd";
import { LoginRequest, useLoginMutation } from "@/api/loginApi";
import { useAppDispatch } from "@/redux/hooks";
import { setRefreshToken, setToken } from "@/slices/tokenSlice";
import { setUserInfo } from "@/slices/userSlice";
import { useLazyGetStaffByIdQuery } from "@/api/staffApi";
import { setRole } from "@/slices/roleSlice";
import { LoginTypeEnum } from "@/enums";
import LoginInByPassword from "./password";
import LoginInByCaptcha from "./code";
import styles from "./index.module.less";

type Props = {
  hidden: boolean;
  onForget: () => void;
};

const LoginPanel: React.FC<Props> = (props) => {
  const { hidden, onForget } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginTrigger, { isLoading: isLoginLoading }] = useLoginMutation();
  const [getStaffTrigger, { isLoading: isGetStaffLoading }] =
    useLazyGetStaffByIdQuery();

  const [form] = Form.useForm();

  const [loginType, setLoginType] = useState<LoginTypeEnum>(
    LoginTypeEnum.PASSWORD
  );
  const [showForget, setShowForget] = useState<boolean>(true);

  const isPasswordPanel = useMemo(
    () => loginType === LoginTypeEnum.PASSWORD,
    [loginType]
  );

  const handleFinish = async (
    value: Pick<LoginRequest, "tel" | "password" | "captcha">
  ) => {
    try {
      const payload = await loginTrigger({
        tel: value.tel,
        password: isPasswordPanel ? value.password : undefined,
        captcha: !isPasswordPanel ? value.captcha : undefined,
        loginType: loginType,
      }).unwrap();
      if (!payload) return;
      const {
        token,
        refreshToken,
        userInfo: { id: userId },
      } = payload;
      dispatch(setToken(token));
      dispatch(setRefreshToken(refreshToken));
      const userInfo = await getStaffTrigger(userId).unwrap();
      dispatch(setUserInfo(userInfo));
      dispatch(setRole(userInfo.menus || []));
      if (!userInfo) return;
      navigate("/home", { replace: true });
      message.success("登录成功");
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleTabsChange = (activeKey: string) => {
    setLoginType(
      activeKey === LoginTypeEnum.PASSWORD
        ? LoginTypeEnum.PASSWORD
        : LoginTypeEnum.CAPTCHA
    );

    setShowForget(activeKey === LoginTypeEnum.PASSWORD);
  };

  const items: TabsProps["items"] = [
    {
      key: LoginTypeEnum.PASSWORD,
      label: "密码登录",
      children: <LoginInByPassword required={isPasswordPanel} />,
    },
    {
      key: LoginTypeEnum.CAPTCHA,
      label: "验证码登录",
      children: <LoginInByCaptcha required={!isPasswordPanel} />,
    },
  ];

  return (
    <Form
      name="login"
      form={form}
      onFinish={handleFinish}
      className={`${styles.container} ${hidden ? styles.hidden : undefined}`}
    >
      <Tabs size="large" centered items={items} onChange={handleTabsChange} />
      <div style={{ height: 20 }} />
      <Button
        block
        htmlType="submit"
        type="primary"
        size="large"
        shape="round"
        loading={isLoginLoading || isGetStaffLoading}
      >
        登录
      </Button>
      <div style={{ height: 6 }} />
      {showForget ? (
        <Flex justify="center">
          <Button type="link" style={{ marginTop: 10 }} onClick={onForget}>
            忘记密码?
          </Button>
        </Flex>
      ) : (
        <div style={{ height: 60 }} />
      )}
    </Form>
  );
};

export default React.memo(LoginPanel);
