import { Form, Input } from "antd";
import { isChinaPhoneLegal } from "@/utils/validator";

const AccountInput = () => {
  return (
    <Form.Item
      name="tel"
      rules={[
        {
          required: true,
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error("请输入账号"));
            }
            if (!isChinaPhoneLegal(value))
              return Promise.reject(new Error("请输入正确手机号码"));

            return Promise.resolve();
          },
        },
      ]}
    >
      <Input size="large" placeholder="请输入账号" allowClear />
    </Form.Item>
  );
};

export default AccountInput;
