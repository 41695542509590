export enum TemplateTypeEnum {
  SINGLE_PHOTO = 1, // 单图
  MULTIPLE_PHOTO = 2, // 组图
}

// 模版选择方式
export enum TemplateSelectTypeEnum {
  RANDOM = 1, // 系统随机筛选
  CHOOSE = 2, // 客户自选
}

export enum GoodsStatusEnum {
  ENABLE = 1,
  DISABLE = 2,
}

export enum ChargesMethodEnum {
  PHOTO = 1,
  TEMPLATE = 2,
}

export enum GoodsClassifyEnum {
  GOODS = 1,
  GOODS_COMBINED = 2,
}

export enum AddPurchasedDiscountTypeEnum {
  PACKALL = 1,
  FULLDECREMENT = 2,
  DISCOUNT = 3,
  SINGLEDISCOUNT = 4,
  COMBOS = 5,
  SATISFYGIFT = 6,
  EVERYFULLDISCOUNT = 7,
}

export const TEMPLATE_TYPE_OPTIONS = [
  { value: TemplateTypeEnum.SINGLE_PHOTO, label: "单图" },
  { value: TemplateTypeEnum.MULTIPLE_PHOTO, label: "组图" },
];
