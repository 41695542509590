import dayjs, { Dayjs } from "dayjs";
import { TablePaginationConfig } from "antd";
import { ApiErrorData } from "@/api/base";
import { EnumCategory, EnumType } from "@api/enumApi";
import { UploadFile } from "@/components/cos-upload";
import type { Dict, DictCategory } from "@/api/dict";

//根据category找枚举
export const findEnumByCategory = (
  categoryCode: string,
  enumCategories: EnumCategory[] | undefined
): EnumType[] => {
  return (
    enumCategories?.find((category) => category.name === categoryCode)?.enums ||
    []
  );
};

//根据category找字典
export const findDictByCategory = (
  categoryCode: string,
  dictList: DictCategory[] | undefined
): Dict[] => {
  return (
    dictList?.find((category) => category.code === categoryCode)?.dicts || []
  );
};

//价格类的数据,前端展示除100
export const priceToPresentation = (
  data: number | string | { [x: string]: number } | undefined,
  code: string | string[] = "price"
) => {
  if (
    (typeof data === "number" || typeof data === "string") &&
    !Number.isNaN(data)
  ) {
    return Number((Number(data) / 100).toFixed(2));
  }

  if (typeof data === "object") {
    if (typeof code === "string") {
      return Object.assign({}, data, {
        [code]: Number((data[code] / 100).toFixed(2)),
      });
    }

    if (code instanceof Array) {
      let newData: any = {};
      code.forEach((codeKey) => {
        newData[codeKey] = Number((data[codeKey] / 100).toFixed(2));
      });
      return Object.assign({}, data, newData);
    }
  }
  return data;
};

//价格类的数据，传给后端乘100

export const priceToDatabase = (data: number | string) => {
  if (isNaN(Number(data))) throw new Error("priceToDatabase:请传入数字");
  return Number((Number(data) * 100).toFixed(0));
};

//手机号隐藏四位
export const hidePhoneFourNum = (tel: string) => {
  return tel.substring(0, 3) + "****" + tel.substring(tel.length - 4);
};

export const timeSliceUntilDay = (time?: string) => {
  return time ? time.split(" ")[0] : "";
};

// 计算删除 x 条数据后的当前页
export const calculatePage = (pageInfo: TablePaginationConfig, x: number) => {
  const { current = 0, pageSize = 0, total = 0 } = pageInfo;
  if (Math.ceil((total - x) / pageSize) > current) {
    return current;
  } else {
    return Math.ceil((total - x) / pageSize);
  }
};

// 导出
export const handelExport = async (
  response: any,
  exportName: string,
  type = "application/xlsx"
) => {
  let blob = new Blob([await response.blob()], {
    type: type + ";charset=utf-8",
  });
  let src = window.URL.createObjectURL(blob);
  if (src) {
    let a = document.createElement("a");
    a.setAttribute("href", src);
    a.setAttribute("download", exportName);
    a.click();
  }
};

// 去重
export const unique = (target: any[], attr?: string | number) => {
  const map = new Map();
  target.forEach((item) => map.set(attr ? item[attr] : item.id, item));

  const uniqueArr = Array.from(map.values());

  return uniqueArr;
};

// dayjs传给后端
export const dayjsToString = (value: Dayjs, format?: string) => {
  if (!value) return;

  const newformat = format ?? "YYYY-MM-DD HH:mm:ss";
  return dayjs(value).isValid() ? dayjs(value).format(newformat) : null;
};

// 后端传的时间string转为dayjs
export const stringToDayjs = (value: string | undefined) =>
  dayjs(value).isValid() ? dayjs(value) : dayjs();

export const transfromError2ApiErrorData = (error: any) => {
  if (error?.name === "ApiError" && error.stack) {
    return JSON.parse(error.stack) as ApiErrorData;
  }
};

/** 时间range数组，转为字段 */
export const normalizeDateRange = (
  values: any,
  fieldName: string,
  options?: {
    startName?: string;
    endName?: string;
    isMaxRange?: boolean;
    format?: string;
  }
) => {
  const {
    startName = "startTime",
    endName = "endTime",
    isMaxRange = false,
    format = "YYYY-MM-DD HH:mm:ss",
  } = options ?? {};
  if (
    !!values[fieldName] &&
    values[fieldName] instanceof Array &&
    values[fieldName].length > 0
  ) {
    return Object.assign({}, values, {
      [startName]:
        transformUTCFormatString(
          values[fieldName]?.[0],
          isMaxRange ? "YYYY-MM-DD 00:00:00" : format
        ) || null,
      [endName]:
        transformUTCFormatString(
          values[fieldName]?.[1],
          isMaxRange ? "YYYY-MM-DD 23:59:59" : format
        ) || null,
    });
  }
  return values;
};

export const transformUTCFormatString = (
  utcTime: string,
  formatString: string = "YYYY-MM-DD HH:mm:ss"
) => {
  if (utcTime && dayjs(utcTime).isValid()) {
    return dayjs(utcTime).format(formatString);
  }
  return utcTime;
};

export const checkImageDone = (images: UploadFile[]) => {
  const isUploading = images.some((img) => img.status === "uploading");
  if (isUploading) throw new Error("图片未上传完成，请稍后");
  const isError = images.some((img) => img.status === "error");
  if (isError) throw new Error("有图片上传错误，请检查");
  return true;
};

export const hiddenTel = (tel: string) => {
  const reg = /^(\d{3})\d{4}(\d{4})$/;
  return tel.replace(reg, "$1****$2");
};
