import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = 10;

const globalPageSizeSlice = createSlice({
  name: "globalPageSize",
  initialState: INITIAL_STATE,
  reducers: {
    setGlobalPageSize: (_, action: PayloadAction<number>): number =>
      action.payload,
    reset: () => INITIAL_STATE,
  },
});

export const { setGlobalPageSize, reset } = globalPageSizeSlice.actions;
export default globalPageSizeSlice.reducer;
