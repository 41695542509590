import { Modal } from "antd";
export default async function checkAppNewVersion() {
  const url = `/version.json?t=${Date.now()}`;
  let res = null;
  try {
    const result = await fetch(url);
    res = await result.json();
    if (!res) return;
    const version = res.version;
    const localVersion = window.localStorage.getItem("appversion");
    if (localVersion && localVersion !== version) {
      window.localStorage.setItem("appversion", version);
      Modal.info({
        title: "发现新版本",
        okText: "更新",
        onOk: () => window.location.reload(),
      });
    } else {
      window.localStorage.setItem("appversion", version);
    }
  } catch (err) {
    // console.error("checkAppNewVersion error: ", err);
  }
}
