import { PaginationResult, PaginationSearch } from "@/types/commonType";
import { baseApi } from "./base";
import { Menu } from "./roleApi";
import { BaseUser } from "./loginApi";
import { Store } from "./storeApi";

export type Roles = {
  id: string;
  title: string;
  code: number;
  description: string;
  menuIds: number[];
  menus: Menu[];
};

export type UpdateStaffPasswordDto = {
  tel: string;
  inputCaptcha: string;
  inputPassword: string;
};

export type StaffInput = {
  belongToStoreIds: string[];
  keyword: string;
  status: number;
  roleCode: number;
  merchantType: number;
};

export type Staff = BaseUser & {
  limitedStoreDtos?: Store[];
  roles?: Roles[];
  employeeNo: string;
  avatar: string;
  menus: Menu[];
  merchantId: string;
  storeType: number;
  merchantType: number;
};

export type CreateStaff = {
  roles: string[];
  merchantType: number;
  storeType: number;
  name: string;
  tel: string;
  employeeNo?: string;
  limitedStores: string[];
};

export type UpdateStaff = CreateStaff & {
  id: string;
};

const StaffApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentStaff: builder.query<Staff, void>({
      query: () => ({
        url: "/m/erp/staff",
        method: "get",
      }),
      providesTags: ["Staff"],
    }),
    getStaffById: builder.query<Staff, string>({
      query: (id) => ({
        url: `/m/erp/staff/${id}`,
        method: "get",
      }),
      providesTags: ["Staff"],
    }),
    updatePassword: builder.mutation<void, UpdateStaffPasswordDto>({
      query: (data) => ({
        url: "/m/erp/staff/modify/password",
        method: "post",
        body: data,
      }),
      invalidatesTags: ["Staff"],
    }),
    getStaffList: builder.mutation<
      PaginationResult<Staff>,
      PaginationSearch<StaffInput>
    >({
      query: (data) => ({
        url: "/m/erp/staff/query",
        method: "post",
        body: data,
      }),
    }),
    getCaptchaWithModifyPassword: builder.query<void, string>({
      query: (tel) => ({
        url: `/m/erp/staff/modify/password/captcha/${tel}`,
        method: "get",
      }),
    }),
    enableStaff: builder.mutation<void, { id: string; type: number }>({
      query: ({ id, type }) => ({
        url: `/m/erp/staff/status/enable/${id}`,
        method: "put",
        params: { type },
      }),
      invalidatesTags: ["Staff"],
    }),
    diableStaff: builder.mutation<void, { id: string; type: number }>({
      query: ({ id, type }) => ({
        url: `/m/erp/staff/status/disable/${id}`,
        method: "put",
        params: { type },
      }),
      invalidatesTags: ["Staff"],
    }),
    createStaff: builder.mutation<void, CreateStaff>({
      query: (data) => ({
        url: `/m/erp/staff`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["Staff"],
    }),
    updateStaff: builder.mutation<void, UpdateStaff>({
      query: (data) => ({
        url: `/m/erp/staff`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Staff"],
    }),
  }),
});

export const {
  useLazyGetCurrentStaffQuery,
  useLazyGetStaffByIdQuery,
  useGetStaffByIdQuery,
  useUpdatePasswordMutation,
  useGetStaffListMutation,
  useLazyGetCaptchaWithModifyPasswordQuery,
  useEnableStaffMutation,
  useDiableStaffMutation,
  useCreateStaffMutation,
  useUpdateStaffMutation,
} = StaffApi;

export default StaffApi;
