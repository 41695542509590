export enum FrameGridTypeEnum {
  ONE = 0,
  FOUR = 1,
  SIX = 2,
  EIGHT = 3,
}

export const BIG_HEAD_GRID_TYPE_OPTIONS = [
  { value: FrameGridTypeEnum.ONE, label: "一宫格" },
  { value: FrameGridTypeEnum.FOUR, label: "四宫格" },
  { value: FrameGridTypeEnum.SIX, label: "六宫格" },
  { value: FrameGridTypeEnum.EIGHT, label: "八宫格" },
];
