import { matchPath } from "react-router-dom";
import fullMenu, { LocalMenuType } from "@/constants/menu";
import { store } from "@redux/store";
import { Menu } from "@/api/roleApi";

export const hasAuth = (menucode: string) => {
  const flattenAuthList = store.getState().role.flattenAuthList || [];
  return !!flattenAuthList.find((auth) => auth.menuCode === menucode);
};

export const hasAuthByRoutePath = (path: string) => {
  const flattenAuthMenuList = store.getState().role.flattenAuthMenuList || [];
  if (flattenAuthMenuList.length === 0) return true;
  return !!flattenAuthMenuList.find((auth) =>
    routePathMatch(path, auth.path || "")
  );
};

//  判断路由是否一致
export const routePathMatch = (path: string, menuPath: string | string[]) => {
  if (typeof menuPath === "object") {
    return menuPath.some((item) => matchPath(item, path));
  }
  return !!matchPath(menuPath, path);
};

// 获取菜单路由
export const getRoutePath = (localMenu: LocalMenuType) => {
  return localMenu.path
    ? typeof localMenu.path === "object"
      ? localMenu.path[0]
      : localMenu.path
    : null;
};

export const getFlattenAuth = (
  authList: Menu[],
  flattenAuthList: Menu[],
  level?: number
) => {
  authList.forEach((roleItem) => {
    if (!roleItem.level || (level && roleItem.level > level)) return;
    flattenAuthList.push(Object.assign({}, roleItem, { childList: [] }));
    getFlattenAuth(roleItem.childList || [], flattenAuthList, level);
  });
};

export const getFlattenAuthMenu = (
  authMenuList: LocalMenuType[],
  flattenAuthMenuList: LocalMenuType[],
  level?: number
) => {
  authMenuList.forEach((roleItem) => {
    if (level && roleItem.level > level) return;
    flattenAuthMenuList.push(Object.assign({}, roleItem, { children: [] }));
    getFlattenAuthMenu(roleItem.children || [], flattenAuthMenuList, level);
  });
};

export const getFlattenMenu = (
  fullMenu: LocalMenuType[],
  flattenMenu: LocalMenuType[]
) => {
  fullMenu.forEach((menuItem) => {
    flattenMenu.push(Object.assign({}, menuItem, { children: [] }));
    getFlattenMenu(menuItem.children || [], flattenMenu);
  });
};

const traverseMenu = (
  menuList: LocalMenuType[],
  authCodes: string[],
  authMenuList: LocalMenuType[],
  level?: number
) => {
  menuList.forEach((menuItem) => {
    if (
      !!menuItem.hidden ||
      !menuItem.level ||
      (level && menuItem.level > level)
    )
      return;
    if (authCodes.includes(menuItem.menucode)) {
      let newAuthMenu: LocalMenuType = Object.assign({}, menuItem, {
        children: undefined,
      });
      let newAuthMenuChildren: LocalMenuType[] = [];
      if (menuItem.children && menuItem.children.length > 0) {
        traverseMenu(menuItem.children, authCodes, newAuthMenuChildren, level);
      }
      if (newAuthMenuChildren.length > 0) {
        newAuthMenu.children = newAuthMenuChildren;
      }
      authMenuList.push(newAuthMenu);
    }
  });
};

//获取左侧菜单列表
export const getAuthMenu = (defaultFlattenAuth?: Menu[], level?: number) => {
  const flattenRoleAuth =
    defaultFlattenAuth || store.getState().role.flattenAuthList;
  let authCodes: string[] = [];
  const authMenu: LocalMenuType[] = [];
  authCodes = flattenRoleAuth.map((auth) => auth.menuCode);
  traverseMenu(fullMenu, authCodes, authMenu, level);
  return authMenu;
};

// // 获取用户在当前门店下的角色
// export const getCurrentUserRoleCode = () => {
//   let roles = store.getState().user.roles;
//   if (roles && roles.length !== 0) {
//     let roleCodeList = roles[0].roles.map((role) => role.roleCode);
//     return roleCodeList;
//   }
//   return [];
// };

// // 判断是否有指定的角色（任一满足即可）
// export const hasRoleCode = (...value: string[]) => {
//   let roleCodeList = Array.from(value);
//   let currentUserRoleCodeList = getCurrentUserRoleCode();
//   return roleCodeList.some((roleCode) =>
//     currentUserRoleCodeList.includes(roleCode)
//   );
// };
