import React, { useCallback, useMemo, useState } from "react";
import { Avatar, Dropdown, message, Modal } from "antd";
import { useAppSelector } from "@redux/hooks";
import {
  DownOutlined,
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLazyLogoutQuery } from "@/api/loginApi";
import PIC_AVATAR from "@/images/avatar.png";
import UpdatePasswordModal from "./update-password-modal";
import UserInfoModal from "./user-info-modal";
import styles from "./index.module.less";

type Props = {
  className?: string;
};

const UserButton: React.FC<Props> = (props) => {
  const { className } = props;

  const { name, tel, id, avatar } = useAppSelector((state) => state.user);

  const [logoutTrigger, { isLoading }] = useLazyLogoutQuery();

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  useState<boolean>(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] =
    useState<boolean>(false);

  const handleLogout = useCallback(() => {
    Modal.confirm({
      title: "温馨提示",
      content: "确定要退出登录吗?",
      cancelText: "取消",
      okText: "确定",
      okType: "primary",
      okButtonProps: { loading: isLoading },
      onOk: async () => {
        try {
          await logoutTrigger(id || "").unwrap();
        } catch (e) {
          message.error("logout fail,e=" + e);
        }
      },
    });
  }, [id]);

  const menuItems = useMemo(() => {
    let menus = [];

    menus.push({
      key: "1",
      label: (
        <div onClick={() => setIsUserModalOpen(true)}>
          <UserOutlined /> <span>个人资料</span>
        </div>
      ),
    });
    menus.push(
      {
        key: "3",
        label: (
          <div onClick={() => setIsPasswordModalOpen(true)}>
            <EditOutlined /> <span>修改密码</span>
          </div>
        ),
      },
      {
        key: "4",
        label: (
          <div onClick={handleLogout}>
            <LogoutOutlined /> <span>退出登录</span>
          </div>
        ),
      }
    );
    return menus;
  }, [handleLogout]);

  return (
    <div className={styles.container}>
      <Dropdown
        className={`${styles.dropdown} ${className}`}
        menu={{ items: menuItems }}
        placement="bottom"
        arrow
        trigger={["click"]}
      >
        <div className={styles.button}>
          <Avatar size={25} src={avatar ?? PIC_AVATAR} />
          <span className={styles.name}>{name}</span>
          <DownOutlined />
        </div>
      </Dropdown>
      <UserInfoModal
        isOpen={isUserModalOpen}
        onCancel={() => setIsUserModalOpen(false)}
      />

      <UpdatePasswordModal
        isOpen={isPasswordModalOpen}
        onCancel={() => setIsPasswordModalOpen(false)}
        tel={tel!}
      />
    </div>
  );
};

export default React.memo(UserButton);
