import React, { useMemo } from "react";
import { Avatar, Col, Modal, Row } from "antd";
import { useAppSelector } from "@/redux/hooks";
import PIC_AVATAR from "@/images/avatar.png";
import styles from "./index.module.less";

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};
const UserInfoModal: React.FC<Props> = (props) => {
  const { isOpen, onCancel } = props;
  const user = useAppSelector((state) => state.user);

  const currentRoles = useMemo(() => {
    return user?.roles?.map((res) => res.title).join("、");
  }, [user]);

  return (
    <Modal title="个人资料" open={isOpen} onCancel={onCancel} footer={null}>
      <Row className={styles.rowDisplay}>
        <Col span={5}>
          <div className={styles.labelName}>头像：</div>
        </Col>
        <Col span={19}>
          <Avatar
            className={styles.avatar}
            size={30}
            src={user.avatar ?? PIC_AVATAR}
          />
        </Col>
      </Row>
      <Row className={styles.rowDisplay}>
        <Col span={5}>
          <div className={styles.labelName}>姓名：</div>
        </Col>
        <Col span={19}>
          <div className={styles.readOnly}>{user.name}</div>
        </Col>
      </Row>
      <Row className={styles.rowDisplay}>
        <Col span={5}>
          <div className={styles.labelName}>手机号：</div>
        </Col>
        <Col span={19}>
          <div className={styles.readOnly}>{user.tel}</div>
        </Col>
      </Row>
      <Row className={styles.rowDisplay}>
        <Col span={5}>
          <div className={styles.labelName}>工号：</div>
        </Col>
        <Col span={19}>
          <div className={styles.readOnly}>{user.employeeNo}</div>
        </Col>
      </Row>
      <Row>
        <Col span={5}>
          <div className={styles.labelName}>具备权限：</div>
        </Col>
        <Col span={19}>
          <div className={styles.readOnly}>{currentRoles}</div>
        </Col>
      </Row>
    </Modal>
  );
};

export default UserInfoModal;
