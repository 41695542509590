import { clear as tokenClear } from "@slices/tokenSlice";
import { clear as tableCacheClear } from "@slices/tableCacheSlice";
import { clear as userInfoClear } from "@slices/userSlice";
import { clear as roleClear } from "@slices/roleSlice";
import { reset as resetGlobalPageSize } from "@/slices/globalPageSizeSlice";
import { baseApi } from "./base";

export type BaseUser = {
  id: string;
  name: string;
  tel: string;
  status: number;
};

export type LoginResult = {
  token: string;
  refreshToken: string;
  userInfo: BaseUser;
};

export type LoginRequest = {
  loginType: string;
  tel: string;
  password?: string;
  captcha?: string;
};

const LoginApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResult, LoginRequest>({
      query: (data) => ({
        url: "/m/erp/login",
        method: "post",
        body: data,
      }),
    }),
    loginByCaptcha: builder.query<void, string>({
      query: (tel) => ({
        url: `/m/erp/login/staff/captcha/${tel}`,
        method: "get",
      }),
    }),
    logout: builder.query<void, string>({
      query: (userId) => ({
        url: "/m/erp/logout",
        method: "get",
        params: { userId },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
        } finally {
          dispatch(tokenClear());
          dispatch(userInfoClear());
          dispatch(roleClear());
          dispatch(tableCacheClear());
          dispatch(resetGlobalPageSize());
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyLoginByCaptchaQuery,
  useLazyLogoutQuery,
} = LoginApi;

export default LoginApi;
