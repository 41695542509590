import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, message, Modal } from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import { debounce } from "lodash";
import {
  useUpdatePasswordMutation,
  UpdateStaffPasswordDto,
  useLazyGetCaptchaWithModifyPasswordQuery,
} from "@/api/staffApi";
import { useAppSelector } from "@/redux/hooks";
import { hiddenTel } from "@/utils";

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  tel: string;
};
const UpdatePasswordModal: React.FC<Props> = (props) => {
  const { isOpen, onCancel } = props;

  const tel = useAppSelector((state) => state.user.tel);

  const [form] = Form.useForm();

  const [updatePasswordTrigger, { isLoading }] = useUpdatePasswordMutation();
  const [getCaptchaTrigger] = useLazyGetCaptchaWithModifyPasswordQuery();

  const [isSend, setIsSend] = useState<boolean>(false);
  const [time, setTime] = useState<number>();

  const handleSendTelCode = async () => {
    try {
      if (!tel) return;
      await getCaptchaTrigger(tel).unwrap();
      message.success("验证码发送成功");
      setTime(Date.now() + 2 * 60 * 1000);
      setIsSend(true);
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleOk = debounce(() => {
    form.submit();
  }, 500);

  const resetForm = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    onCancel();
    resetForm();
  };

  const handleFinish = async (value: Omit<UpdateStaffPasswordDto, "tel">) => {
    try {
      if (!tel) return;
      await updatePasswordTrigger(
        Object.assign({}, value, {
          tel,
        })
      ).unwrap();
      message.success("修改密码成功");
      handleCancel();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    setIsSend(false);
  }, [isOpen]);

  return (
    <Modal
      title="修改密码"
      open={isOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ loading: isLoading }}
      styles={{
        body: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
      width={470}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label="手机号">
          {tel && <span style={{ marginRight: 10 }}>{hiddenTel(tel)}</span>}
          <Button
            type="primary"
            style={{ width: 100, fontSize: 14 }}
            onClick={isSend ? undefined : handleSendTelCode}
          >
            {isSend ? (
              <Countdown
                valueStyle={{
                  color: "white",
                  fontSize: "14px",
                }}
                value={time}
                onFinish={() => setIsSend(false)}
                format="s"
              />
            ) : (
              "发送验证码"
            )}
          </Button>
        </Form.Item>
        <Form.Item
          label="验证码"
          name="inputCaptcha"
          rules={[{ required: true, message: "请输入验证码" }]}
        >
          <Input type={"text"} placeholder="请输入验证码" />
        </Form.Item>
        <Form.Item
          label="密码"
          name="inputPassword"
          rules={[
            {
              message: "请输入正确的密码格式",
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z]{8,20}$/,
            },
            { required: true, message: "请输入密码" },
          ]}
        >
          <Input type={"text"} placeholder="请输入密码" />
        </Form.Item>
        <Alert
          message=" 密码长度8-20位，须包含大写字母、小写字母和数字3种元素。"
          style={{ backgroundColor: "#f5f5f5", fontSize: 12 }}
        />
      </Form>
    </Modal>
  );
};

export default UpdatePasswordModal;
