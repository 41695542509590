import { useEffect, useMemo } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { message } from "antd";
import { useAppSelector } from "@redux/hooks";
import { hasAuthByRoutePath, routePathMatch } from "@/utils/roleAuthority";
import checkAppVersion from "./checkAppVersion";

const routeWhiteList = ["/home", "/login"];

const AuthLayout = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { token } = useAppSelector((state) => state.token);

  const isLoginPage = useMemo(() => {
    return routePathMatch(pathname, "/login");
  }, [pathname]);

  const hasAuthPath = useMemo(() => {
    return (
      routePathMatch(pathname, routeWhiteList) || hasAuthByRoutePath(pathname)
    );
  }, [pathname]);

  useEffect(() => {
    if (!token && !isLoginPage) {
      setTimeout(() => {
        message.error("为了您的账号安全，请重新登录");
      }, 10);
    }
  }, [token, isLoginPage]);

  useEffect(() => {
    if (token) {
      checkAppVersion();
    }
  }, [pathname, token]);

  if (!token && !isLoginPage) return <Navigate to="/login" replace />;
  if (!hasAuthPath) return <Navigate to="/404" replace />;
  return children;
};

export default AuthLayout;
