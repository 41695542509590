import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TOKEN_STORAGE, REFRESH_TOKEN_STORAGE } from "@/constants/storage";

type StateType = {
  token: string;
  refreshToken: string;
};
const initialState: StateType = {
  token: "",
  refreshToken: "",
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      window.localStorage.setItem(TOKEN_STORAGE, action.payload);
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
      window.localStorage.setItem(REFRESH_TOKEN_STORAGE, action.payload);
    },
    clear: () => {
      window.localStorage.removeItem(TOKEN_STORAGE);
      window.localStorage.removeItem(REFRESH_TOKEN_STORAGE);
      return {
        token: "",
        refreshToken: "",
      };
    },
  },
});

export const { setToken, setRefreshToken, clear } = tokenSlice.actions;

export default tokenSlice.reducer;
