import React, { useCallback, useMemo } from "react";
import { Breadcrumb } from "antd";
import { useLocation, Link } from "react-router-dom";
import fullMenu, { LocalMenuType } from "@/constants/menu";
import {
  getFlattenMenu,
  getRoutePath,
  hasAuth,
  routePathMatch,
} from "@/utils/roleAuthority";
import styles from "./index.module.less";

type Props = {
  className?: string;
};

const RouterBreadcrumb: React.FC<Props> = (props) => {
  const { className } = props;
  const location = useLocation();
  const pathname = location.pathname;

  const flattenMenu = useMemo(() => {
    let menu: LocalMenuType[] = [];
    getFlattenMenu(fullMenu, menu);
    return menu;
  }, []);

  const matchMenu = useMemo(() => {
    return flattenMenu.find((menu) => {
      return menu.path && routePathMatch(pathname, menu.path || "");
    });
  }, [flattenMenu, pathname]);

  const findParentMenu = useCallback(
    (menu: LocalMenuType, matchMenuList: LocalMenuType[]) => {
      if (!menu.path) return;
      matchMenuList.unshift(menu);
      if (!menu.parentmenuid) return;
      let parentMenu = flattenMenu.find(
        (item) => item.key === menu.parentmenuid
      );
      if (parentMenu) {
        findParentMenu(parentMenu, matchMenuList);
      }
    },
    [flattenMenu]
  );

  const serializeBreadCrumb = useMemo(() => {
    if (matchMenu) {
      let matchMenuList: LocalMenuType[] = [];
      findParentMenu(matchMenu, matchMenuList);
      return matchMenuList;
    }

    return [];
  }, [matchMenu, findParentMenu]);

  const serializeBreadCrumbItems = useMemo(() => {
    return serializeBreadCrumb.map((menu, index) => {
      return {
        key: menu.key,
        label:
          hasAuth(menu.menucode) && index !== serializeBreadCrumb.length - 1 ? (
            <Link
              to={
                index === serializeBreadCrumb.length - 1
                  ? pathname
                  : getRoutePath(menu)!
              }
            >
              {menu.label!}
            </Link>
          ) : (
            menu.label
          ),
      };
    });
  }, [pathname, serializeBreadCrumb]);

  return (
    <div className={`${styles.container} ${className}`}>
      {serializeBreadCrumb.length > 0 && (
        <Breadcrumb>
          {serializeBreadCrumbItems.map((item) => (
            <Breadcrumb.Item key={item.key}>{item.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
};

export default React.memo(RouterBreadcrumb);
