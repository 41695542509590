export enum CouponTypeEnum {
  CASH_COUPON = 1, //现金券
  EXCHANGE_COUPON = 2, //兑换券
}

export enum CouponStatusEnum {
  ENABLE = 1,
  DISABLE = -1,
}

export enum CouponTimeEnum {
  TODAY = 1, //当天
  EFFECTIVE_DAY = 2, //指定天数
}

export enum OrderSourceEnum {
  SHOOT_DEVICE = 1, //拍照机
  APPLET = 2, //小程序
}
