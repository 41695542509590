import React, { useState } from "react";
import { Button, Form, Input, message, Space, Statistic } from "antd";
import { useLazyLoginByCaptchaQuery } from "@/api/loginApi";
import AccountInput from "../../components/account-input";

type Props = {
  required: boolean;
};

const LoginInByCaptcha: React.FC<Props> = (props) => {
  const { required } = props;

  const { Countdown } = Statistic; //倒计时

  const [getCaptchaTrigger] = useLazyLoginByCaptchaQuery();

  const form = Form.useFormInstance();

  const [isSendCaptcha, setIsSendCaptcha] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>();

  const handleSendCode = async () => {
    try {
      const tel = form.getFieldValue("tel");
      if (!tel) {
        message.warning("请先输入手机号");
        return;
      }
      await getCaptchaTrigger(tel).unwrap();
      setCountDown(Date.now() + 2 * 60 * 1000);
      setIsSendCaptcha(true);
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleCountDown = () => {
    setIsSendCaptcha(false);
  };

  return (
    <>
      <AccountInput />
      <Space.Compact block>
        <Form.Item
          name="captcha"
          noStyle
          rules={[{ required, message: "请输入验证码" }]}
        >
          <Input size="large" placeholder="请输入验证码" />
        </Form.Item>

        <Button
          size="large"
          type="primary"
          style={{ width: 120, fontSize: 14 }}
          onClick={isSendCaptcha ? undefined : handleSendCode}
        >
          {isSendCaptcha ? (
            <Countdown
              valueStyle={{
                color: "white",
                fontSize: "14px",
              }}
              value={countDown}
              onFinish={handleCountDown}
              format="s"
            />
          ) : (
            "发送验证码"
          )}
        </Button>
      </Space.Compact>

      <div style={{ height: 24 }} />
    </>
  );
};

export default React.memo(LoginInByCaptcha);
