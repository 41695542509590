import { Form, Input } from "antd";
import React from "react";
import AccountInput from "../../components/account-input";

type Props = {
  required: boolean;
};

const LoginInByPassword: React.FC<Props> = (props) => {
  const { required } = props;

  return (
    <>
      <AccountInput />

      <Form.Item
        name="password"
        rules={[{ required, message: "请输入登录密码" }]}
      >
        <Input.Password size="large" placeholder="请输入登录密码" />
      </Form.Item>
    </>
  );
};

export default React.memo(LoginInByPassword);
