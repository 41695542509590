import React from "react";
import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { config as particleConfig } from "./particle.config";

const Tsparticles = () => {
  const [isParticlesInit, setIsParticlesInit] = useState<boolean>(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setIsParticlesInit(true);
    });
  }, []);

  if (isParticlesInit)
    return <Particles id="tsparticles" options={particleConfig} />;

  return <></>;
};

export default React.memo(Tsparticles);
