import { useEffect, useState } from "react";
import "antd/dist/reset.css";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ErrorBoundary from "./components/error-boundary";
import router from "./router";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useLazyGetCurrentStaffQuery } from "./api/staffApi";
import { setRole } from "./slices/roleSlice";
import { setUserInfo } from "./slices/userSlice";
import { setRefreshToken, setToken } from "./slices/tokenSlice";
import { REFRESH_TOKEN_STORAGE, TOKEN_STORAGE } from "./constants/storage";
import "./App.css";

dayjs.locale("zh-cn");
dayjs.extend(customParseFormat);

function App() {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.token.token);
  const [getCurrentStaffTrigger] = useLazyGetCurrentStaffQuery();

  const [isInitial, setInitial] = useState(false);

  const refreshUerInfo = async () => {
    try {
      const data = await getCurrentStaffTrigger().unwrap();
      dispatch(setRole(data.menus || []));
      dispatch(setUserInfo(data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const token = window.localStorage.getItem(TOKEN_STORAGE);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_STORAGE);
    if (token) {
      dispatch(setToken(token));
    }
    if (refreshToken) {
      dispatch(setRefreshToken(refreshToken));
    }

    setTimeout(() => {
      setInitial(true);
    }, 0);
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      refreshUerInfo();
    }
  }, [token]);

  if (!isInitial) return null;

  return (
    <ConfigProvider locale={zhCN}>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
