import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/es/storage";
import {
  persistStore,
  persistReducer,
  PersistConfig,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { baseApi } from "../api/base";
import TokenReducer from "../slices/tokenSlice";
import UserReducer from "../slices/userSlice";
import RoleReducer from "../slices/roleSlice";
import tableCacheSlice from "../slices/tableCacheSlice";
import globalPageSizeSlice from "../slices/globalPageSizeSlice";

// 持久化配置
const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: "root", // 存储的键
  storage, // 使用localStorage
  whitelist: ["globalPageSize"], // 仅持久化这些reducer
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  token: TokenReducer,
  user: UserReducer,
  role: RoleReducer,
  tableCache: tableCacheSlice,
  globalPageSize: globalPageSizeSlice,
});

// 创建持久化 reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["role"],
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER, // 忽略 redux-persist 相关的 actions
        ],
      },
    }).concat(baseApi.middleware),
});

// 创建 persistor，用于控制持久化存储
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);
