import { useState } from "react";
import Tsparticles from "@/components/tsparticles";
import LoginPanel from "./login-panel";
import ForgetPanel from "./forget-panel";

const LoginPage = () => {
  const [hidden, setHidden] = useState<boolean>(false);

  const handleForget = () => {
    setHidden(true);
  };

  const handleForgetPanelCancel = () => {
    setHidden(false);
  };

  return (
    <>
      <Tsparticles />
      <LoginPanel hidden={hidden} onForget={handleForget} />
      <ForgetPanel hidden={!hidden} onCancel={handleForgetPanelCancel} />
    </>
  );
};

export default LoginPage;
