export enum MachineStatusEnum {
  ERROR = 0,
  ACTIVE = 1,
  WAIT_ACTIVE = 2,
}

export enum DeviceTypeEnum {
  CAMERA = 1,
  PRINTER = 2,
  PHOTO_PRINTER_INTEGRATION = 3,
}

export enum CameraDirectionEnum {
  HORIZONTAL = 1,
  VERTICAL = 2,
}

export const DEVICE_TYPE_OPTIONS = [
  { label: "拍照机", value: DeviceTypeEnum.CAMERA },
  { label: "打印机", value: DeviceTypeEnum.PRINTER },
  { label: "拍印一体机", value: DeviceTypeEnum.PHOTO_PRINTER_INTEGRATION },
];

export const CAMERA_DIRECTION = [
  { label: "横向", value: CameraDirectionEnum.HORIZONTAL },
  { label: "纵向", value: CameraDirectionEnum.VERTICAL },
];

export const MACHINE_TYPE_OPTIONS = [
  { label: "异常", value: MachineStatusEnum.ERROR },
  { label: "正常", value: MachineStatusEnum.ACTIVE },
  { label: "待激活", value: MachineStatusEnum.WAIT_ACTIVE },
];
