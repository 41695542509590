import React from "react";
import { Button, Space } from "antd";
import PIC_LOGO from "@/images/logo.png";
import styles from "./index.module.less";

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<React.PropsWithChildren, State> {
  constructor(props: React.PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.uploadLog(error, errorInfo);
  }

  async uploadLog(error: any, errorInfo: any) {
    console.log("error", error);
    console.log("errorInfo", errorInfo);
  }

  reloadWindow() {
    window.location.reload();
  }

  goHome() {
    window.location.replace(window.location.origin);
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <div className={styles.container}>
          <img src={PIC_LOGO} alt="logo" className={styles.logo} />
          <h1>很抱歉，页面出错了！</h1>
          <Space size="middle">
            <Button type="primary" onClick={this.reloadWindow}>
              刷新页面
            </Button>
            <Button onClick={this.goHome}>返回首页</Button>
          </Space>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
