import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalMenuType } from "@/constants/menu";
import {
  getAuthMenu,
  getFlattenAuth,
  getFlattenAuthMenu,
} from "@/utils/roleAuthority";
import { Menu } from "@/api/roleApi";

const initialState: {
  authTree: Menu[];
  flattenAuthList: Menu[];
  authMenuTree: LocalMenuType[];
  flattenAuthMenuList: LocalMenuType[];
} = {
  authTree: [],
  flattenAuthList: [],
  authMenuTree: [],
  flattenAuthMenuList: [],
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<Menu[]>) => {
      state.authTree = action.payload;
      let authList: Menu[] = [];
      let authMenuTree: LocalMenuType[] = [];
      let authMenuList: LocalMenuType[] = [];
      getFlattenAuth(action.payload, authList);
      authMenuTree = getAuthMenu(authList);
      getFlattenAuthMenu(authMenuTree, authMenuList);
      state.flattenAuthList = authList;
      state.authMenuTree = authMenuTree;
      state.flattenAuthMenuList = authMenuList;
    },
    clear: () => {
      return {
        authTree: [],
        flattenAuthList: [],
        authMenuTree: [],
        flattenAuthMenuList: [],
      };
    },
  },
});

export const { setRole, clear } = roleSlice.actions;

export default roleSlice.reducer;
