/** 菜单类型 */
export enum MenuTypeEnum {
  /** 平台菜单 */
  PLATFORM = 2,
  /** 商户菜单 */
  SELLER = 1,
}

/** 角色类型 */
export enum RoleTypeEnum {
  /** 品牌管理员 */
  BRAND_MANAGER = 1,
  /** 店长 */
  STORE_MANAGER = 2,
  /** 定制 */
  OPERATION = 3,
  /** 定制 */
  CUSTOMIZE = 4,
}
