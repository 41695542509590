import React, { useState } from "react";
import {
  Alert,
  Button,
  Flex,
  Form,
  Input,
  message,
  Space,
  Statistic,
} from "antd";
import {
  useUpdatePasswordMutation,
  UpdateStaffPasswordDto,
  useLazyGetCaptchaWithModifyPasswordQuery,
} from "@/api/staffApi";
import styles from "./index.module.less";

type Props = {
  hidden: boolean;
  onCancel: () => void;
};

const ForgetPanel: React.FC<Props> = (props) => {
  const { hidden, onCancel } = props;

  const [form] = Form.useForm();
  const { Countdown } = Statistic; //倒计时

  const [getCaptchaTrigger] = useLazyGetCaptchaWithModifyPasswordQuery();
  const [updatePasswordTrigger] = useUpdatePasswordMutation();

  const [isSendCaptcha, setIsSendCaptcha] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>();

  const handleCountDown = () => {
    setIsSendCaptcha(false);
  };

  const handleSendCode = async () => {
    try {
      const tel = form.getFieldValue("tel");
      if (!tel) {
        message.warning("请先输入手机号");
        return;
      }
      setIsSendCaptcha(true);
      setCountDown(Date.now() + 2 * 60 * 1000);
      await getCaptchaTrigger(tel).unwrap();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleFinish = async (value: UpdateStaffPasswordDto) => {
    try {
      await updatePasswordTrigger(value).unwrap();
      message.success("修改密码成功");
      handleCancel();
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const resetForm = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    onCancel();
    resetForm();
  };

  return (
    <Form
      form={form}
      name="forget"
      labelAlign="right"
      labelCol={{ span: 4 }}
      className={`${styles.container} ${hidden ? styles.hidden : undefined}`}
      onFinish={handleFinish}
    >
      <Form.Item label="手机号" required className={styles.alignMiddle}>
        <Space.Compact block>
          <Form.Item
            noStyle
            name="tel"
            rules={[{ required: true, message: "请输入手机号" }]}
          >
            <Input size="large" placeholder="请输入手机号" />
          </Form.Item>

          <Button
            type="primary"
            size="large"
            style={{ width: 120, fontSize: 14 }}
            onClick={isSendCaptcha ? undefined : handleSendCode}
          >
            {isSendCaptcha ? (
              <Countdown
                valueStyle={{
                  color: "white",
                  fontSize: "14px",
                }}
                value={countDown}
                onFinish={handleCountDown}
                format="s"
              />
            ) : (
              "发送验证码"
            )}
          </Button>
        </Space.Compact>
      </Form.Item>

      <Form.Item
        label="验证码"
        name="inputCaptcha"
        rules={[{ required: true, message: "请输入验证码" }]}
        className={styles.alignMiddle}
      >
        <Input size="large" placeholder="请输入验证码" />
      </Form.Item>

      <Form.Item
        label="密码"
        name="inputPassword"
        rules={[
          { required: true, message: "请输入密码" },
          {
            message: "请输入正确的密码格式",
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z]{8,20}$/,
          },
        ]}
        className={styles.alignMiddle}
      >
        <Input.Password size="large" placeholder="请输入密码" />
      </Form.Item>

      <Alert
        message=" 密码长度8-20位，须包含大写字母、小写字母和数字3种元素。"
        style={{ backgroundColor: "#f5f5f5", fontSize: 12 }}
      />

      <Flex justify="center" className={styles.operation}>
        <Space>
          <Button size="large" onClick={handleCancel}>
            取消
          </Button>
          <Button size="large" type="primary" htmlType="submit">
            确定
          </Button>
        </Space>
      </Flex>
    </Form>
  );
};

export default ForgetPanel;
