export enum CheckedEnum {
  SELECT = "1",
  UNSELECT = "2",
}

export enum SettingTypeEnum {
  HOME = 1,
  VIP = 2,
  OTHER = 3,
  CLASSIFICATION = 4,
}
