import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { PaginationSearch } from "@/types/commonType";
import { type TagTypes } from "@/hooks/table-cache-hooks/keeperType";

export type CacheData = {
  search: PaginationSearch<any>;
  total: number;
};

export type TableCacheState = {
  tag: TagTypes | undefined;
  cacheData: CacheData | undefined;
};

const initialState: TableCacheState = {
  tag: undefined,
  cacheData: undefined,
};

export const tableCache = createSlice({
  name: "tableCache",
  initialState,
  reducers: {
    setState: (
      _,
      action: PayloadAction<CacheData & { tag: TagTypes | undefined }>
    ): TableCacheState => {
      const { tag, ...resetData } = action.payload;

      return {
        tag,
        cacheData: { ...resetData },
      };
    },
    clearTagAndCacheData: (): TableCacheState => {
      return {
        tag: undefined,
        cacheData: undefined,
      };
    },
    clear: (): TableCacheState => {
      return initialState;
    },
  },
});

export const { setState, clearTagAndCacheData, clear } = tableCache.actions;

export default tableCache.reducer;
