import { useState, Suspense, useEffect, useMemo, useCallback } from "react";
import { Layout, Menu, Space, Spin } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import LOGO from "@/images/logo.png";
import { HomeLayoutContext } from "@/pages/home/reactContext";
import {
  getAuthMenu,
  getRoutePath,
  routePathMatch,
} from "@/utils/roleAuthority";
import { useAppSelector } from "@/redux/hooks";
import UserButton from "./user-button";
import RouterBreadcrumb from "./router-breadcrumb";
import styles from "./index.module.less";

const { Header, Sider, Content } = Layout;

const HomePage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const flattenAuthList = useAppSelector(
    (state) => state.role.flattenAuthList || []
  );
  const flattenAuthMenuList = useAppSelector(
    (state) => state.role.flattenAuthMenuList || []
  );

  const [collapsed, setCollapsed] = useState(false);
  const [hidePadding, setHidePadding] = useState(false);
  const [menuSelectKeys, setMenuSelectKeys] = useState<string[]>();
  const [menuOpenKeys, setMenuOpenKeys] = useState<string[]>();

  //1,2级菜单list
  const authMenuList = useMemo(() => {
    return getAuthMenu(flattenAuthList, 2);
  }, [flattenAuthList]);

  //2级菜单list
  const secondAuthMenuList = useMemo(() => {
    return flattenAuthMenuList.filter((res) => res.level === 2);
  }, [flattenAuthMenuList]);

  //根据key获取2级菜单
  const findSecondMenuByKey = useCallback(
    (key: string) => {
      return secondAuthMenuList.find((menuItem) => menuItem.key === key);
    },
    [secondAuthMenuList]
  );

  const handleMenuChange = useCallback(
    ({ key }: { key: string }) => {
      setMenuSelectKeys([key]);
      let chooseItem = findSecondMenuByKey(key);
      if (chooseItem?.path) navigate(getRoutePath(chooseItem) || "");
    },
    [findSecondMenuByKey, navigate]
  );

  const handleMenuOpenChange = useCallback((openKeys: string[]) => {
    setMenuOpenKeys(openKeys);
  }, []);

  //初始化菜单
  useEffect(() => {
    const initRoute =
      flattenAuthMenuList.find((menuItem) =>
        routePathMatch(pathname, menuItem.path || "")
      ) || secondAuthMenuList[0];
    if (initRoute) {
      navigate(initRoute.level > 2 ? pathname : getRoutePath(initRoute)!, {
        replace: true,
      });
    }
  }, [flattenAuthMenuList, secondAuthMenuList]);

  //根据路由更新菜单
  useEffect(() => {
    const matchMenu = flattenAuthMenuList.find((menuItem) =>
      routePathMatch(pathname, menuItem.path || "")
    );
    if (matchMenu) {
      matchMenu.level > 2
        ? setMenuSelectKeys([matchMenu.parentmenuid!])
        : setMenuSelectKeys([matchMenu.key]);
      const newSecondMenu =
        matchMenu.level > 2
          ? findSecondMenuByKey(matchMenu.parentmenuid!)
          : matchMenu;
      if (newSecondMenu)
        setMenuOpenKeys((preOpenKeys) => {
          const openKeysSet = new Set(preOpenKeys || []);
          openKeysSet.add(newSecondMenu.parentmenuid!);
          return Array.from(openKeysSet);
        });
    } else {
      setMenuSelectKeys([]);
    }
  }, [findSecondMenuByKey, flattenAuthMenuList, pathname]);

  if (flattenAuthList.length === 0) return null;

  return (
    <Layout className={styles.container}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={styles.scrollView}
      >
        {collapsed ? (
          <img src={LOGO} alt="logo" className={styles.logo2} />
        ) : (
          <div className={styles.logo}>铂爵智拍商户端</div>
        )}

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={menuSelectKeys}
          items={authMenuList}
          onSelect={handleMenuChange}
          openKeys={menuOpenKeys}
          onOpenChange={handleMenuOpenChange}
        />
      </Sider>

      <Layout className={styles.rightLayout}>
        <Header className={styles.header}>
          <div className={styles.headerLeft}>
            <Space>
              {collapsed ? (
                <MenuUnfoldOutlined onClick={() => setCollapsed(false)} />
              ) : (
                <MenuFoldOutlined onClick={() => setCollapsed(true)} />
              )}
              <RouterBreadcrumb />
            </Space>
          </div>
          <div className={styles.headerRight}>
            <UserButton />
          </div>
        </Header>

        <HomeLayoutContext.Provider value={{ hidePadding, setHidePadding }}>
          <Content
            className={`${styles.content} ${
              hidePadding ? styles.hidePaadingContent : ""
            }`}
          >
            <Suspense
              fallback={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spin />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </Content>
        </HomeLayoutContext.Provider>
      </Layout>
    </Layout>
  );
};

export default HomePage;
