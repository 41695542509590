import LibPhoneNumber from "google-libphonenumber";

const phoneUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();

// 大陆手机号码11位数
export const isChinaPhoneLegal = (str: string) => {
  return RegExp("^1\\d{10}$").test(str);
};

//大于0，且保留两位小数
export const isGreaterThanZero = (value: string) => {
  return RegExp(
    "^0\\.([1-9]|\\d[1-9])$|^[1-9]\\d{0,8}\\.\\d{0,2}$|^[1-9]\\d{0,8}$"
  ).test(value);
};

//表单校验失败滚动
export const isFormValidFailed = () => {
  //由于表单dom更新是异步的，可能获取不到，可以在回调函数中操作
  return setTimeout(() => {
    const errorList = (document as any).querySelectorAll(
      ".ant-form-item-has-error"
    );
    if (errorList.length > 0) {
      errorList[0].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, 500);
};

// 校验 \ / : * ? " < > | 特服符号是否存在
export const isInvalidChart = (inputValue: any) => {
  const invalidChartList = ["\\", "/", ":", "*", "?", '"', "<", ">", "|"];

  const isSlashExist = invalidChartList.some(
    (invalidChart) => inputValue.indexOf(invalidChart) > -1
  );

  return isSlashExist;
};

export const isValidPhoneNumber = (number: string) => {
  try {
    const result = phoneUtil.parse(number, "CN");
    if (phoneUtil.isValidNumber(result)) {
      return result;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const getMainlandNumber = (number: string) => {
  try {
    const result = phoneUtil.parse(number, "CN");
    if (phoneUtil.isValidNumber(result)) {
      if (result.getCountryCode() === 86) {
        return result.getNationalNumber();
      }
      return number;
    }
    return "";
  } catch (e) {
    return "";
  }
};

export const isNumberOrLetter = (value: string) => {
  return /^[A-Za-z0-9]+$/.test(value);
};
