export enum ActivityTypeEnum {
  HOME_CAROUSEL = 1,
  NEARBY_WALKING = 2,
}

export enum ActivityPageStyleEnum {
  GOODS = 1,
  DETAIL = 2,
}

export enum ActivityStatusEnum {
  WAIT_ACTIVATE = 1,
  ACTIVATING = 2,
  ENDED = 3,
}

export const ACTIVITY_TYPE_OPTIONS = [
  {
    name: "首页轮播",
    value: ActivityTypeEnum.HOME_CAROUSEL,
    createButtonTitle: "新增首页轮播",
  },
  {
    name: "附近走一走",
    value: ActivityTypeEnum.NEARBY_WALKING,
    createButtonTitle: "新增走一走",
  },
];

export const ACTIVITY_STATUS_OPTIONS = [
  { name: "未开始", value: ActivityStatusEnum.WAIT_ACTIVATE },
  { name: "进行中", value: ActivityStatusEnum.ACTIVATING },
  { name: "已结束", value: ActivityStatusEnum.ENDED },
];
